body{
    background-image: url('../Images/cf-main-bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
}
*{
    font-family: 'Rubik', sans-serif;
}
.main-body{
    margin: 0% 8% 1% 8%;
    background: rgb(255, 255, 255, 0.7);
    padding: 30px;
    border-radius: 10px;
}
button{
    border: none;
}
p,ol{
    font-size: 18px;
}

.main-logo{
    width: 50%;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 20px;
    margin-top: 8px;
}
.top-menu{
    height: 100px;
    box-shadow: -1px 10px 13px -4px rgba(54,54,54,0.75);
    -webkit-box-shadow: -1px 10px 13px -4px rgba(54,54,54,0.75);
    -moz-box-shadow: -1px 10px 13px -4px rgba(54,54,54,0.75);
        z-index: 2;
    position: fixed;
    top: 0;
    width: 100%;
}
.home-banner{
    height: 600px;
    background-position: 100% 60%;
    background-size: cover;
    background-repeat: no-repeat;
}
.banner-text{
    width: 46%;
}
.banner-text > h2{
    font-size: 350%;
    color: white;
    padding-top: 35%;
    padding-left: 10%;
    line-height: 1.3;
}
.get-started{
    margin-left: 5%;
    border-radius: 50px;
    padding: 10px 20px;
    font-size: 20px;
    border: none;
    background: rgb(241, 241, 241);
    text-decoration: none;
    color: rgb(0, 0, 0);
}
.get-started:hover{
    background: rgb(29, 29, 29);
    color: white;
    transition: 0.3s;
}
.get-started > i{
    margin-left: 10px;
}
.menu-item{
    margin: 0px 9px;
}
.navbar-brand{
    width: 25%;
}
.title-banner{
    width: 100%;
    color: black;
    height: 300px;
    margin-top: 100px;
    position: relative;
}
.title-banner > h1{
text-align: right;
margin-right: 7%;
font-size: 450%;
width: 100%;
bottom: 0;
position: absolute;
padding-right: 8%;
color: white;
}
.menu-item > a{
    color: black;
}
.full-text-section{
    padding: 5% 5%;
}
.heading-line{
    width: 70px;
    background: rgb(216, 118, 5);
    height: 7px;
    border-radius: 50px;
    margin-bottom: 20px;
    line-height: 1.9;
}
.full-text{
    font-size: 125%;
    line-height: 1.6;
}
.gallery{
width: 100%;
height: 100%;
display: flex;
flex-wrap: wrap;
justify-content: center;
}
.modal-content{
    min-height: 100%;
    border-radius: 0px;
}
.inner-gallery-modal{
    height: 500px;
}
.gallery-image{
    width: 20%;
    margin: 10px;
    border-radius: 5px;
}
.gallery-close{
    margin-left: auto;
}
.gallery-close button{
    background: none;
    color: white;
    font-size: 20px;
}
.open-image{
    width: 100%;
}
.gallery-modal{
    background: none;
    border: none;
}
.gallery-modal .btn-close{
    color: white;
}
.image-gallery-image{
    width: 100%;
}
.load-placeholder{
    width: 100%;
    text-align: center;
}
.gallery-image:hover{
    transform: scale(0.9);
    transition: 0.1s;
}
.modal-placeholder{
    height: 500px
}
.gallery-btn-con{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.gallery-load-button{
    margin-left: auto;
    margin-right: auto;
    border: none;
}
.centralised-item{
    margin-left: auto;
    margin-right: auto;
}
.key-data{
    display: flex;
    flex-wrap: wrap;
    gap: 5%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 20px;
    padding-bottom: 20px;
}

.key-data-column{
    text-align: center;
    padding: 10px;
    background: rgb(17, 17, 17);
    width: 30%;
    color: white;
    border-radius: 10px;
}
.key-data-div{
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    width: 90%;
    background: rgb(17, 17, 17);
    font-size: 17px;
    line-height: 1.2;
    padding-top: 60px;
    padding-bottom: 60px;
}
.white-icon{
    color: white;
    font-size: 60px;
}
.key-data-icon{
    font-size: 250%;
    margin-bottom: 12px;
    padding-bottom: 20px;
    border-bottom: 3px solid white;
}
.brand-box{
    display: grid;
    background: white;
    grid-template-columns: 30% 60%;
    grid-gap: 10%;
    border: 1px solid rgb(189, 189, 189);
    border-radius: 10px;
    padding: 20px 10px;
    box-shadow: 0px 0px 21px -4px rgba(212,212,212,0.75);
    -webkit-box-shadow: 0px 0px 21px -4px rgba(212,212,212,0.75);
    -moz-box-shadow: 0px 0px 21px -4px rgba(212,212,212,0.75);
    text-decoration: none;
    color: inherit;
}
.brand-box:hover{
    background: rgb(49, 49, 49);
    color: white;
    transition: 0.3s;
}
.brand-logo{
    width: 80%;
}
.split-two{
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
}
.brand-box > h5{
    text-align: left;
    margin-top: auto;
    margin-bottom: auto;
}
.centralised-full{
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
}
.big-btn{
    font-size: 160%;
    width: fit-content;
    padding: 10px 40px;
    text-align: center;
    background: white;
    position: relative;
    bottom: 0px;
    margin-top: 3%;
}
.large-text{
    font-size: 500%;
    font-weight: lighter;
}
.outline-btn{
    border: 3px solid white;
    background: none;
    color: white;
}
.footer{
    width: 100%;
    background: rgb(39, 39, 39);
    display: flex;
    flex-wrap: wrap;
    gap: 7%;
    padding: 2.5% 5%;
    margin-top: 80px;
}
.ft-1,.ft-2,.ft-3, .ft-4{
    color: white;
    text-decoration: none;
}
.footer > * > a {
    color: white;
    text-decoration: none;
}
.ft-1 > img {
    width: 35%;
    margin-bottom: 20px;
}
.ft-1{
    width: 40%;
}
.ft-3 a {
    color: white;
    text-decoration: none;
}
.centered{
    text-align: center;
}
.white{
    color: white;
}
.orange{
    color: rgb(223, 134, 0);
}
.text-heading>h2{
    font-size: 26px;
}
.fancy-title{
    background: rgb(219, 132, 0);
    color: white;
    width: fit-content;
    padding: 3px 15px;
}
.primary-button{
    background: rgba(233, 151, 0, 0.925);
    color: white;
}
.secondary-button{
    background: rgb(37, 37, 37);
    border: none;
}
.big-btn{
    font-size: 20px;
    padding: 10px;
}
.clear-button{
    background: none;
    border: 2px solid rgb(26, 26, 26);
}
.video-box .big-btn{
    margin-bottom: auto;
}
.black{
    color: black;
}
.trustee-div{
    display: flex;
    flex-wrap: wrap;
    gap: 7%;
    margin-right: auto;
    margin-left: auto;
}
.person-bio{
    background: rgb(223, 108, 0);
    width: 27%;
    border-radius: 10px;
    color: white;
    line-height: 1.3;
    border: 1px solid rgb(165, 165, 165);
}
.form-control{
    margin-bottom: 10px;
}
.nav-underline .nav-link{
    margin-left: 30px;
  }
.video-box{
    padding: 2%;
    background: rgb(245, 161, 5);
    width: 90%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    gap: 3%;
}
.video{
    border-radius: 10px;
    width: 40%;
    height: 300px;
}
.video-text{
    float: right;
    height: 100%;
    width: 50%;
}

.video-text .btn{
    margin-bottom: auto;
}
.nav-link{
    color: black;
}
.nav-link:hover{
    color: rgb(255, 166, 0);
}
.leadership-div{
    display: flex;
    flex-wrap: wrap;
    gap: 3%;
    margin-right: auto;
    margin-left: auto;
}
.modal-header{
    border: none;
}
.leadership-div > .person-bio{
    width: 22%;

}
.bio-text span{
 font-size: 15px;  
}
.bio-image{
    height: 320px;
    border-radius: 10px;
}
.special-banner{
    background-position: 0px -90px;
    height: 330px;
}
.contact-form{
    width: 50%;
    padding: 20px;
    border: 1px solid rgb(231, 112, 0);
border-radius: 15px;
}
@media only screen and (max-width: 767px) {
    .home-banner{
        width: 100%;
        background-position: -540px -20px;
    }
    .title-banner{
        height: 400px;
        background-size:cover;
        margin-bottom: 0%;
    }
    .title-banner > h1{
        margin-bottom: 10%;
        font-size: 300%;
    }
    .main-body{
        padding-top: 0%;
    }
    .navbar-collapse{
        background: rgb(240, 240, 240);
        width: 100%;
        border-radius: 15px;
        padding: 10px;
    }
    .navbar-brand{
        width: 40%;
    }
    .main-logo{
        width: 100%;
        margin-top: 0%;

    }
    .banner-text > h2{
        font-size: 210%;
        text-align: left;
        padding-top: 90%;
        padding-left: 10%;
        margin-bottom: 0%;
    }
    .key-data{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .key-data-column{
        width: 100%;
        margin-bottom: 10px;
    }
    .key-data-div > h2{
        width: 70%;
        margin-left: auto;
        margin-right: auto;
    }
    .brand-box{
        width: 100%;
    }
    .apply-box > .large-text{
        font-size: 360%;
        font-weight: 300;
    }
    .apply-box .big-btn{
        padding: 10px 30px;
        font-size: 25px;
    }
    .apply-box{
        background-position: -100px 0px;
    }
    .footer{ 
    padding: 30px 20px 40px 20px;
    gap: 25%;
    }
    .ft-1{
        width: 100%;
    }
    .ft-1 > img{
        width: 35%;
        margin-bottom: 40px;
    }
    .lower-body{
        margin-top: 50px;
    }
    .special-banner{
        height: 400px;
        background-size:cover;
        margin-bottom: 0%;
        background-position: 0px 0px;
    }
    .contact-form{
        width: 100%;
        padding: 5px;
    }
    .trustee-div, .leadership-div{
        flex-direction: column;
    }
    .person-bio, .leadership-div > .person-bio{
        width: 100%;
        margin-bottom: 30px;
    }
    .gallery{
        flex-direction: column;
    }
    .gallery-image{
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
.inner-gallery-modal{
    height: fit-content;
  }
  .video-box{
    flex-wrap: wrap;
    padding: 20px;
    width: 90%;
  }
  .video{
    width: 100%;
    margin-bottom: 20px;
  }
  .nav .video-box{
    width: 100%;
  }
  .nav{
    margin: none;
  }
  .video-text{
    width: 100%;
  }
  
  }